import { User } from "@medusajs/medusa";
import { queryClient } from "configs/MedusaConfig";
import {
  useAdminDeleteSession,
  useAdminGetSession,
  useAdminStore,
} from "medusa-react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { connect } from "react-redux";
import { useAuth } from "./auth-context";
import { MILD_YEARLY_DISCOUNT, SPICY_YEARLY_DISCOUNT } from "configs/AppConfig";
import { MetaTagBackground } from "utils/meta-update";

export type PlanKeys =
  | "public_price"
  | "mild_yearly"
  | "spicy_yearly"
  | "plus_yearly"
  | "pro_yearly"
  | null;

export interface ExtendedUser extends User {
  social_link?: string;
  website?: string;
  phone_number?: string;
  creative_type?: string;
  insta_access_token?: string;
  intercom_hash?: string;
  facebook_business_access_token?: string;
  facebook_business_token_expiration?: Date;
  last_login: Date;
  theme_editor: boolean;
  onboarding_data: {
    utm_campaign: string;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    app_installed: boolean;
    plan_position: number;
    last_app_login: Date;
    initial_plan_interval: string;
    free_trial: string;
    completion: number;
    trial_days_period: number;
    interested_selling: boolean;
    onboarding_monetization_categories: { category: string }[];
    referrer: string;
    next_step?: string;
    last_completed_step?: string;
  };
}

interface IAccountContext {
  user?: Omit<ExtendedUser, "password_hash">;
  isLoadingUser: boolean;
  currentPricing: PlanKeys;
  identifyUser: () => void;
  refetchUser: () => Promise<any>;
  handleLogout: () => void;
  displayCurrentPricing: (public_price: number) => number;
}

const AccountContext = createContext<IAccountContext | null>(null);

interface AccountProviderProps {
  children?: React.ReactNode;
}

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { token } = useAuth();

  const {
    user: userData,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useAdminGetSession({ enabled: !!token });

  const user = userData as Omit<ExtendedUser, "password_hash">;
  const { setToken } = useAuth();
  const { store, isLoading: loadingStore } = useAdminStore({
    enabled: !!token,
  });

  const currentPricing = useMemo(() => {
    if (loadingStore || !store) {
      return null;
    }
    if (store?.subscription_interval !== "yearly") {
      return "public_price";
    }
    if (
      store?.subscription_interval === "yearly" &&
      store?.subscription_tier === "mild"
    ) {
      return "mild_yearly";
    }
    if (
      store?.subscription_interval === "yearly" &&
      store?.subscription_tier === "spicy"
    ) {
      return "spicy_yearly";
    }
    if (
      store?.subscription_interval === "yearly" &&
      store?.subscription_tier === "pro"
    ) {
      return "pro_yearly";
    }
    if (
      store?.subscription_interval === "yearly" &&
      store?.subscription_tier === "plus"
    ) {
      return "plus_yearly";
    }
    return "public_price";
  }, [store, loadingStore]);

  useEffect(() => {
    const clearUser = async () => {
      //Invalidate all cache and refetch user
      if (token) {
        await queryClient.clear();
        await refetchUser();
      }
    };
    clearUser();
  }, [token]);

  const identifyUser = useCallback(() => {
    try {
      if (user) {
        window["identify"](user.email);
      }
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  const { mutate: deleteSession } = useAdminDeleteSession();

  const handleLogout = () => {
    deleteSession(undefined, {
      onSuccess: () => {
        setToken();
        queryClient.clear();
        MetaTagBackground("#fff");
      },
    });
  };

  const displayCurrentPricing = (public_price: number) => {
    return currentPricing === "mild_yearly" || currentPricing === "plus_yearly"
      ? Math.round(public_price * (1 - MILD_YEARLY_DISCOUNT))
      : currentPricing === "spicy_yearly" || currentPricing === "pro_yearly"
      ? Math.round(public_price * (1 - SPICY_YEARLY_DISCOUNT))
      : public_price;
  };

  return (
    <AccountContext.Provider
      value={{
        user,
        isLoadingUser,
        currentPricing,
        identifyUser,
        refetchUser,
        handleLogout,
        displayCurrentPricing,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

connect(mapStateToProps)(AccountProvider);
