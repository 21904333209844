import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { jwtDecode } from "jwt-decode";
import { message } from "antd";
import { setSharedCookie } from "utils/helper";

// Define the shape of the context value
interface AuthContextType {
  token: string | null;
  setToken: (newToken?: string) => void;
  authContextInitialized: boolean;
}

// Create the context with an initial undefined value but specify the type
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Props type for the AuthProvider component, supporting children of type ReactNode
interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // State to hold the authentication token, with type string or null
  const [token, setToken_] = useState<string | null>(
    localStorage.getItem(AUTH_TOKEN)
  );
  const [authContextInitialized, setAuthContextInitialized] = useState(false);

  // Function to check if the token is expired
  const isTokenExpired = (token: string) => {
    if (!!token && token !== null) {
      try {
        const decoded: { exp: number } = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decoded.exp < currentTime;
      } catch (e) {
        return true;
      }
    } else {
      return true;
    }
  };

  // Function to set the authentication token, correctly typed
  const setToken = (newToken?: string) => {
    if (!newToken || isTokenExpired(newToken)) {
      setToken_(null);
      localStorage.removeItem(AUTH_TOKEN);
      delete axios.defaults.headers.common["Authorization"];
      return;
    }
    setToken_(newToken);
    localStorage.setItem(AUTH_TOKEN, newToken);
    axios.defaults.headers["authorization"] = "Bearer " + newToken;
  };

  useEffect(() => {
    // Automatically check and handle token expiration on component mount
    if (token && isTokenExpired(token)) {
      setToken(); // This will remove the expired token
      message.info("Your session has expired. Please log in again.");
    }

    // Set an interval to check token expiration regularly
    const interval = setInterval(() => {
      if (token && isTokenExpired(token)) {
        setToken(); // If the token has expired, set it to null
        message.info("Your session has expired. Please log in again.");
      }
    }, 1000 * 60 * 5); // Check every 5 minutes

    setAuthContextInitialized(true);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [token]);

  // Memoized value of the authentication context, now correctly typed
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      authContextInitialized,
    }),
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the auth context, with added type safety
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
